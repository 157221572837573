import React, { Component } from "react"

import TalkToOurTeam from "../homepageInnos/about/TalkToOurTeam"

import IndustriesPageTitle from "../industries/industriesHomePage/insdustriesPageTitle/insdustriesPageTitle"
// import ClientsServices from "../industries/servicesPage/servicesHomePage/servicesClients/clientsServices"
import IndustriesPlatform from "../industries/industriesHomePage/industriesPlatform/industriesPlatform"
import SoftwareIndustries from "../industries/industriesHomePage/softwareIndustries/softwareIndustries"
import TheLastTechIndustries from "../industries/industriesHomePage/theLastTechIndustries/theLastTechIndustries"
import AppManagermentIndustries from "../industries/industriesHomePage/appManagerment/appManagerment"


import ClientsServices from '../servicesPage/servicesHomePage/servicesClients/clientsServices'

class SolutionPage extends Component {
  render() {
    return (
      <>
        <IndustriesPageTitle />
        <IndustriesPlatform />
        <SoftwareIndustries />
        <TheLastTechIndustries />
        <AppManagermentIndustries />
        <ClientsServices />
        <TalkToOurTeam />
      </>
    )
  }
}

export default SolutionPage
