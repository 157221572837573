import React, { useState } from "react"

// import ElmConstruction from "./elmConstruction"
import ElmHealthcare from "./elmHealthcare"
import ElmEvents from "./elmEvents"
// import ElmInsurance from "./elmInsurance"
import ElmRetail from "./elmRetail"
import ElmTravel from "./elmTravel"
// import ElmSport from "./elmSports"
// import ElmMusic from "./elmMusicians"
import ElmMobile from "../ElmMobileIndustries/ElmMobileIndustries"

const BigElmCoverIndustries = props => {
  const [activeDiv, setActive] = useState(`Construction`)

  // const showConstruction = () => {
  //   setActive("Construction")
  // }
  const showHealthcare = () => {
    setActive("Healthcare")
  }
  const showEvents = () => {
    setActive("Events")
  }
  // const showElmInsurance = () => {
  //   setActive("Insurance")
  // }

  const showRetail = () => {
    setActive("Retail")
  }
  const showTravel = () => {
    setActive("Travel")
  }
  // const showSport = () => {
  //   setActive("Sport")
  // }
  // const showMusic = () => {
  //   setActive("Music")
  // }
  return (
    <>
      <div className="snakepit">
        <div className="container">
          <div
            className={`snakepit__phone ${
              activeDiv === "Travel"
                ? "bgI-travel"
                : activeDiv === "Healthcare"
                ? "bgI-healthcare"
                : activeDiv === "Retail"
                ? "bgI-retail"
                : activeDiv === "Events"
                ? "bgI-events"
                : "bgI-travel"
            }`}
          ></div>
          <div className="snakepit__list">
            {/* <div
              className={`snakepit__item ${
                activeDiv === "Construction" ? "js-active" : ""
              }`}
              onClick={showConstruction}
            >
              <ElmConstruction />
            </div> */}
            <div
              className={`snakepit__item ${
                activeDiv === "Healthcare" ? "js-active" : ""
              }`}
              onClick={showHealthcare}
            >
              <ElmHealthcare />
            </div>
            {/* <div
              className={`snakepit__item ${
                activeDiv === "Insurance" ? "js-active" : ""
              }`}
              onClick={showElmInsurance}
            >
              <ElmInsurance />
            </div> */}
            <div
              className={`snakepit__item ${
                activeDiv === "Retail" ? "js-active" : ""
              }`}
              onClick={showRetail}
            >
              <ElmRetail />
            </div>
            <div
              className={`snakepit__item ${
                activeDiv === "Travel" ? "js-active" : ""
              }`}
              onClick={showTravel}
            >
              <ElmTravel />
            </div>
            <div
              className={`snakepit__item ${
                activeDiv === "Events" ? "js-active" : ""
              }`}
              onClick={showEvents}
            >
              <ElmEvents />
            </div>
            {/* <div
              className={`snakepit__item ${
                activeDiv === "Sport" ? "js-active" : ""
              }`}
              onClick={showSport}
            >
              <ElmSport />
            </div> */}
            {/* <div
              className={`snakepit__item ${
                activeDiv === "Music" ? "js-active" : ""
              }`}
              onClick={showMusic}
            >
              <ElmMusic />
            </div> */}
          </div>
          <div className="toggleMobile">
            <ElmMobile />
          </div>
        </div>
      </div>
    </>
  )
}

export default BigElmCoverIndustries
