import React from "react"
import { withTranslation } from "react-i18next"
import { ChevronRight } from "react-feather"
import { Link } from "gatsby"

const ViewMoreIndustries = props => {
  const { t } = props
  return (
    <Link to="/industries" className="snakepit__link--more">
      {t("src.pages.servicesPage.viewMore")}
      <ChevronRight className="rightArr" href="#/" size="17" />
    </Link>
  )
}

export default withTranslation()(ViewMoreIndustries)
