import React from "react"
import { withTranslation } from "react-i18next"
import ViewMoreIndustries from "./viewMoreIndustries"
// import { DataSoftwareIndustries } from "../../../../../data/dataIndustries"
import img1 from "../../../../../assets/img/media/industries/softwareElm/travel.jpg"
const ElmTravel = props => {
  const { t } = props
  return (
    <>
      <div className="snakepit__content snakepit__rectangle--right">
        <h3 className="snakepit__heading">
          {t("src.pages.solutionsPage.software.data")[4].title}
        </h3>
        <p className="snakepit__description">
          {t("src.pages.solutionsPage.software.data")[4].subtitle}
        </p>
        <img
          className="snakepit__screen"
          src={img1}
          alt={t("src.pages.solutionsPage.software.data")[4].title}
        />
        <ViewMoreIndustries />
      </div>
    </>
  )
}

export default withTranslation()(ElmTravel)
