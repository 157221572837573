import React from "react"
import { withTranslation } from "react-i18next"
import ViewMoreIndustries from "./viewMoreIndustries"
// import { DataSoftwareIndustries } from "../../../../../data/dataIndustries"
import img2 from "../../../../../assets/img/media/industries/softwareElm/healthcare.jpg"
const ElmHealthcare = props => {
  const { t } = props
  return (
    <>
      <div className="snakepit__content snakepit__rectangle--left top-border">
        <h3 className="snakepit__heading">
          {t("src.pages.solutionsPage.software.data")[1].title}
        </h3>
        <p className="snakepit__description">
          {t("src.pages.solutionsPage.software.data")[1].subtitle}
        </p>
        <img
          className="snakepit__screen"
          src={img2}
          alt={t("src.pages.solutionsPage.software.data")[1].title}
        />
        <ViewMoreIndustries />
      </div>
    </>
  )
}

export default withTranslation()(ElmHealthcare)
