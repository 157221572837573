import React from "react"
import { withTranslation } from "react-i18next"
// import { DataIndustries } from "../../../../data/dataIndustries"
import "./insdustriesPageTitle.scss"

const IndustriesPageTitle = props => {
  const { t } = props
  return (
    <section className="headerServices header--industries headerIndustries">
      <div className="container">
        <h1 className="title title--uppercase title--margin-top white-text">
          <span className="title__preheader title__preheader--tag">
            {t("src.pages.solutionsPage.solutions")}
          </span>
          {t("src.pages.solutionsPage.title")}
        </h1>
        <p className="header__lead">
          {t("src.pages.solutionsPage.descriptions")}
        </p>
      </div>
    </section>
  )
}

export default withTranslation()(IndustriesPageTitle)
