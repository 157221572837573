import React from "react"
import Layout from "../components/layout/layout.higherComponent"
import Head from "../components/head"
import Solution from "../components/solution/solutionPage"

const Solutions = () => {
  return (
    <div>
      <Layout>
        <Head title="Solutions" />
        <Solution />
      </Layout>
    </div>
  )
}

export default Solutions
