import React from "react"
import { Slide } from "react-slideshow-image"

import ElmConstruction from "./../ElmSoftIndustries/elmConstruction"
import ElmHealthcare from "./../ElmSoftIndustries/elmHealthcare"
import ElmEvents from "./../ElmSoftIndustries/elmEvents"
import ElmInsurance from "./../ElmSoftIndustries/elmInsurance"
import ElmRetail from "./../ElmSoftIndustries/elmRetail"
import ElmTravel from "./../ElmSoftIndustries/elmTravel"
import ElmSport from "./../ElmSoftIndustries/elmSports"
import ElmMusic from "./../ElmSoftIndustries/elmMusicians"

import "./ElmMobileIndustries.scss"

const properties = {
  duration: 10000,
  transitionDuration: 300,
  infinite: true,
  arrows: true
}

const ElmMobile = props => {
  return (
    <>
      <div className="snapkeit-Mobile">
        <Slide {...properties}>
          {/* <div className="snakepit__item">
            <ElmConstruction />
          </div> */}
          <div className="snakepit__item">
            <ElmHealthcare />
          </div>
          {/* <div className="snakepit__item">
            <ElmInsurance />
          </div> */}
          <div className="snakepit__item">
            <ElmRetail />
          </div>
          <div className="snakepit__item">
            <ElmTravel />
          </div>
          <div className="snakepit__item">
            <ElmEvents />
          </div>
          {/* <div className="snakepit__item">
            <ElmSport />
          </div> */}
          {/* <div className="snakepit__item">
            <ElmMusic />
          </div> */}
        </Slide>
      </div>
    </>
  )
}

export default ElmMobile
