import React from "react"
import { withTranslation } from "react-i18next"
import "./clientsServices.scss"
import AboutLogo from "./../../../homepageInnos/about/aboutLogo.components"

const ClientsServices = props => {
  const { t } = props
  return (
    <section id="" className=" cemetery">
      <div className="container-fluid container" style={{ opacity: 1 }}>
        <div
          className="cemetery__title"
          style={{ opacity: 1, marginBottom: "30px" }}
        >
          <h2>{t("src.pages.mobileDevelopmentPage.ourClients")}</h2>
        </div>
        <AboutLogo />
      </div>
    </section>
  )
}

export default withTranslation()(ClientsServices)
