import React from "react"
import { withTranslation } from "react-i18next"
// import { dataTheLastTechIndustries } from "../../../../data/dataIndustries"
import "./theLastTechIndustries.scss"

const TheLastTechIndustries = props => {
  const { t } = props
  return (
    <>
      <div className="apocalypse apocalypse--platforms">
        <div className="container">
          <h2 className="apocalypse__title">
            {t("src.pages.solutionsPage.theLastTech.title")}
          </h2>
          <div className="apocalypse__list">
            {t("src.pages.solutionsPage.theLastTech.data").map(
              (block, index) => (
                <div key={index} className="apocalypse__item">
                  <div className={`last${index + 1} apocalypse__icon`}></div>
                  <div className="apocalypse__content ">
                    <h3 className="apocalypse__heading">{block.title}</h3>
                    <p className="apocalypse__description">{block.content}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(TheLastTechIndustries)
