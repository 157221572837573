import React from "react"
import { withTranslation } from "react-i18next"
import "./softwareIndustries.scss"
import { DataSoftwareIndustriesTitle } from "../../../../data/dataIndustries"
import BigElmCoverIndustries from "./ElmSoftIndustries/BigElmCoverIndustries"

const SoftwareIndustries = props => {
  const { t } = props

  return (
    <div className="industries-section">
      <div className="divider-b-75 divider-t-100">
        <div className="container">
          <div className="grid grid--column grid--center">
            <h2 className="title title--black title--medium title--uppercase">
              {t("src.pages.solutionsPage.software.title")}
            </h2>
          </div>
        </div>
      </div>
      <BigElmCoverIndustries />
    </div>
  )
}

export default withTranslation()(SoftwareIndustries)
