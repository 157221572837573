import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { useUnmount } from "react-use"
// import { DataIndustriesPlatform } from "../../../../data/dataIndustries"
import "./industriesPlatform.scss"

const IndustriesPlatform = props => {
  const { t } = props
  const [addictionalClasses, setClasses] = useState("")

  function checkScreen() {
    if (window.scrollY > 150 && window.scrollY < 1000) setClasses("show")
    else {
      setClasses("")
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", checkScreen)
  }, [])

  useUnmount(() => {
    window.removeEventListener("scroll", checkScreen)
  })

  return (
    <div className="bullseye">
      <div className="bullseye__circle"></div>
      <div className="bullseye__item bullseye__item--25">
        <div className={`bullseye__ipad ${addictionalClasses}`}></div>
      </div>

      <div className="bullseye__item bullseye__item--50">
        <div className="bullseye__content">
          <h3>{t("src.pages.solutionsPage.platform.title")}</h3>
          <h3>{t("src.pages.solutionsPage.platform.subtitle")}</h3>
          <p>{t("src.pages.solutionsPage.platform.content")}</p>

          <div className="bullseye__arrow">
            <span>{t("src.pages.solutionsPage.platform.discover")}</span>
          </div>
        </div>
      </div>

      <div className="bullseye__item bullseye__item--25">
        <div className={`bullseye__macbook ${addictionalClasses}`}></div>
      </div>
    </div>
  )
}

export default withTranslation()(IndustriesPlatform)
