import React from "react"
import { withTranslation } from "react-i18next"
// import { dataAppManagermentIndustries } from "../../../../data/dataIndustries"
import "./AppManagerment.scss"

const AppManagermentIndustries = props => {
  const { t } = props
  return (
    <>
      <div className="divider-b-75 divider-t-100 coverAppMana">
        <div className="container">
          <div className="grid grid--column grid--center">
            <h2 className="title title--black title--medium title--uppercase">
              {t("src.pages.solutionsPage.appManagement.title")}
            </h2>
          </div>
        </div>
      </div>
      <div className="planalitics" style={{ backgroundColor: "#fafafa" }}>
        <div className="container">
          <div className="planalitics__list">
            <ul>
              {t("src.pages.solutionsPage.appManagement.data").map(
                (block, index) => (
                  <li key={index}>
                    <div className={`isoi app${index + 1}`}></div>
                    <h3>{block.title}</h3>
                    <p>{block.content}</p>
                  </li>
                )
              )}
            </ul>
          </div>

          <div className="planalitics__image"></div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(AppManagermentIndustries)
